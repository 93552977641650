import logo from './media/images/logo.svg';
import './App.css';
import SocialButtonsGroup from "./components/SocialButtonsGroup";
import BackgroundVideo from "./components/BackgroundVideo";
import {useEffect, useState} from "react";


function App() {
    const [windowWidth, setWindowWidth] = useState( window.innerWidth )
    const [windowHeight, setWindowHeight] = useState( window.innerHeight )
    const setWindowDimensions = () => {
        setWindowWidth( window.innerWidth )
        setWindowHeight( window.innerHeight )
    }
    useEffect( () => {
        window.addEventListener( 'resize', setWindowDimensions );
        window.addEventListener( 'orientationchange', setWindowDimensions );
        return () => {
            window.removeEventListener( 'resize', setWindowDimensions )
            window.removeEventListener( 'orientationchange', setWindowDimensions )
        }
    }, [] )

    return (
        <>
            {windowWidth < 435 && <BackgroundVideo/>}
            <div className="App"
                 style={{
                     height: windowHeight + "px",
                     flexDirection: windowHeight < windowWidth ? "row" : "column",
                     textAlign: "center",
                     backgroundColor: "rgba(32, 33, 36, 0.6)",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: windowHeight < windowWidth ? "space-evenly" : "space-between"
                 }}>
                <header className="App-header">
                    <div className="Logo-container">
                        <img src={logo} className="App-logo" alt="Wow it's cake logo"/>
                    </div>
                    <h1>
                        Wow it's cake
                    </h1>
                    <h2>Домашняя кондитерская</h2>
                </header>
                <main>
                    <SocialButtonsGroup/>
                </main>
            </div>
        </>

    );
}

export default App;
