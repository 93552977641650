import React from 'react';
import video from "../media/video/bgvideo.mp4";

const BackgroundVideo = () => {
    return (
        <video
            id="bgvid"
            playsInline
            autoPlay
            muted
            loop
            style={{
                objectFit: "cover",
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,

            }}>
            <source src={video} type="video/mp4"/>
        </video>
    );
};

export default BackgroundVideo;