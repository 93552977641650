import React from 'react';
import {Button, Grid} from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import {pink} from '@mui/material/colors';
import {createTheme, ThemeProvider} from "@mui/material/styles";


const theme = createTheme( {
    palette: {
        secondary: {
            main: pink["100"]
        }
    }
} );

const buttons = [
    <Button
        size="large"
        fullWidth
        color="secondary"
        startIcon={<InstagramIcon/>}
        variant="contained"
        key="Instagram"
        href="https://instagram.com/wow.its.cake">
        Instagram
    </Button>,
    <Button
        size="large"
        fullWidth
        color="secondary"
        startIcon={<WhatsAppIcon/>}
        variant="contained"
        key="WhatsApp"
        href="https://wa.me/79166381527">
        WhatsApp
    </Button>,
    <Button
        size="large"
        fullWidth
        color="secondary"
        startIcon={<TelegramIcon/>}
        variant="contained"
        key="Telegram"
        href="https://t.me/ily_anastasia">
        Telegram
    </Button>
]

const SocialButtonsGroup = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="center">
                <Grid
                    container
                    flex
                    flexDirection="column"
                    gap={1}
                    sx={{
                        width: {xs: "60vmin", md: "40vmin"},
                        paddingBottom: {xs: "12vmin", md: "8vmin"}
                    }}
                >
                    {buttons.map( button => (
                        <Grid item>
                            {button}
                        </Grid>
                    ) )}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default SocialButtonsGroup;